import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import SectionLayout from "../SectionLayout"
import ContentContainer from "../ContentContainer"
import ScreenshotLayout from "../ScreenshotLayout"

const Section = ({ bgColor }) => {

  const data = useStaticQuery(graphql`
    query {
      screenshotImg: file(relativePath: { eq: "screen-overtime.png" }) {
        childImageSharp {
          fluid(maxWidth: 1368) {
            ...GatsbyImageSharpFluid
          },
        }
      },
      illustration: file(relativePath: { eq: "illustration-overtime.png" }) {
        childImageSharp {
          fluid(maxWidth: 1155) {
            ...GatsbyImageSharpFluid
          },
        }
      },
    },
  `)

  return (
    <SectionLayout data-sal="bodyBg" data-color={bgColor}>
      <ContentContainer>
        <ScreenshotLayout
          screen={data.screenshotImg.childImageSharp.fluid}
          heading="We are going to overtime!"
          blurb="If one of your little ones needs a bit more time, take it to overtime and put the pressure on."
          illustration={data.illustration.childImageSharp.fluid}
        />
      </ContentContainer>
    </SectionLayout>
  )
}

export default Section
