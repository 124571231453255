import React from "react"
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { color, media } from "../../theme"
import ContentContainer from "../ContentContainer"
import Logo from "../Logo"
import Button from "../Button"
import StoreButtons from "../StoreButtons"
import SocialButtons from "../SocialButtons"

const Wrapper = styled.div`
  padding: 3rem 1rem 0; /* essentially body padding */
  background-color: ${props => color[props.bgColor]};
  /* -10% horizontal margin below would cause horizontal scroll */
  overflow: hidden;

  ${media.md`
      padding-top: 3rem;
  `}

  ${media.lg`
      padding-top: 4rem;
  `}
`

const StyledButton = styled(Button)`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const Grid = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 100%;
  grid-gap: 0.75rem;
  position: relative;
  /* padding-bottom to give space for app store and social buttons */
  /* for single column mobile */
  padding-bottom: 104px; 

  ${media.sm`
      grid-template-columns: 50% 50%;
      margin-left: 9%;
      padding-bottom: 0;
  `}
`

const Left = styled.div`
  text-align: center;
  padding: 0 1.5rem;

  ${media.sm`
      text-align: left;
      padding: 3rem 0 0;
  `}

  ${media.lg`
      text-align: left;
      padding-top: 4rem;
  `}
`;

const LogoWrapper = styled.div`
  width: 200px;
  height: 70.5px;
  margin: 0 auto 1.25rem;

  ${media.sm`
      margin: 0 0 1.25rem;
      width: 215px;
      height: 75.7875px;
  `}

  ${media.md`
      margin: 0 0 2rem;
      width: 215px;
      height: 75.7875px;
  `}

  ${media.lg`
      width: 300px;
      height: 106px;
  `}
`;

const Tagline = styled.h2`
  font-weight: 700;
  font-size: 1.3rem;
  color: ${color.CREME};
  margin-bottom: 1rem;

  ${media.lg`
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
  `}
`

const SubTagline = styled.h3`
  font-weight: 600;
  font-size: 1rem;
  color: ${color.CREME};
  font-style: italic;
  padding: 0 1.2rem;
  opacity: 0.9;
  margin-bottom: 24px;

  ${media.ms`
    padding: 0 1.4rem;
  `}

  ${media.sm`
    padding: 0;
  `}

  ${media.lg`
    font-size: 1.3rem;;
  `}
`

const Right = styled.div`
  /* -10% margin enlarges image within grid a bit and bleed shadow to edge */
  margin-left: -10%;
  margin-right: -10%;
  /*  margin-bottom: -12%; */
  ${media.sm`
      margin-right: 0;
      /* margin-bottom: -8%; */
  `}

  ${media.lg`
      margin-bottom: -4%;
  `}
`;

const StoreButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  bottom: 96px;

  ${media.sm`
    position: static;
    margin-bottom: 24px;
    justify-content: flex-start;
  `}
`

const SocialButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 32px;

  ${media.sm`
    position: static;
    justify-content: flex-start;
  `}
`

const Hero = ({ bgColor }) => {

  const data = useStaticQuery(graphql`
    query {
      screenshotImg: file(relativePath: { eq: "screen-mykids.png" }) {
        childImageSharp {
          fluid(maxWidth: 1368) {
            ...GatsbyImageSharpFluid
          },
        }
      },
    },
  `)

  return (
    <Wrapper bgColor={bgColor}>
      <StyledButton href="/faq">FAQ</StyledButton>
      <ContentContainer>
        <Grid>
          <Left>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Tagline>
              The best way to get your young kids to listen!
            </Tagline>
            <SubTagline>
              Motivate <strike style={{ opacity: 0.65 }}>bribe</strike> your kids using timers and a simple point system.
            </SubTagline>
            <StoreButtonContainer>
              <StoreButtons />
            </StoreButtonContainer>
            <SocialButtonContainer>
              <SocialButtons />
            </SocialButtonContainer>
          </Left>
          <Right>
            <Img
              fluid={data.screenshotImg.childImageSharp.fluid}
              title="Goody Points My Kids Screen"
              alt="Goody Points My Kids Screen"
            />
          </Right>
        </Grid>
      </ContentContainer>
    </Wrapper>
  )
}

export default Hero
