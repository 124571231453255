import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import SectionLayout from "../SectionLayout"
import ContentContainer from "../ContentContainer"
import ScreenshotLayout from "../ScreenshotLayout"

const Section = ({ bgColor }) => {

  const data = useStaticQuery(graphql`
    query {
      screenshotImg: file(relativePath: { eq: "screen-timerstart.png" }) {
        childImageSharp {
          fluid(maxWidth: 1368) {
            ...GatsbyImageSharpFluid
          },
        }
      },
      illustration: file(relativePath: { eq: "illustration-timerstart.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          },
        }
      },
    },
  `)

  return (
    <SectionLayout data-sal="bodyBg" data-color={bgColor}>
      <ContentContainer>
        <ScreenshotLayout
          screen={data.screenshotImg.childImageSharp.fluid}
          heading="Start your timer"
          blurb="Make any quick adjustments and tee up a reward when your kids meet the challenge."
          illustration={data.illustration.childImageSharp.fluid}
        />
      </ContentContainer>
    </SectionLayout>
  )
}

export default Section
