import React from "react"
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { color, media } from "../../theme"
import SectionLayout from "../SectionLayout"
import ContentContainer from "../ContentContainer"

const Heading = styled.h2`
  margin: 0 auto;
  max-width: 90%;
  text-align: center;
  font-weight: 700;
  font-size: 1.2rem;
  color: ${color.TEAL};

  ${media.sm`
      max-width: 75%;
      font-size: 1.4rem;
  `}

  ${media.md`
      font-size: 1.8rem;
  `}
`

const Main = styled.div`
  ${media.md`
    display: flex;
  `}
`

const Subsection = styled.div`
  margin: 3rem auto;
  max-width: 610px;

  ${media.md`
    width: 46%;
  `}
`;

const StepHeading = styled.h3`
  display: flex;
  align-items: center;
`

const Step = styled.p`
  font-size: 1.4rem;
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  font-weight: 800;
  color: ${color.CREME};
  background-color: ${color.TEAL};
  display: flex;
  justify-content: center;
  align-items: center; 

  ${media.sm`
    font-size: 2rem;
    height: 3rem;
    width: 3rem;
    border-radius: 3rem;
  `}
`

const StepLabel = styled.span`
  margin-left: 1rem;
  font-weight: 700;
  font-size: 1.4rem;
  color: ${color.TEAL};

  ${media.sm`
      font-size: 1.8rem;
  `}
`

const StepDescription = styled.p`
  font-size: 1rem;
  color: ${color.TEAL};
  font-weight: 500;
  margin-top: 0.25rem;
  margin-left: 3rem;
  margin-bottom: 1rem;

  ${media.sm`
    margin-left: 4rem;
    margin-bottom: 2rem;
    font-size: 1.1rem;
  `}

  ${media.lg`
      font-size: 1.3rem;
  `}
`

const StepImage1 = styled.div`
  margin-right: -1rem;

  ${media.sm`
    margin-right: 0;
  `}
`

const StepImage2 = styled.div`
  margin-right: -1rem;

  ${media.sm`
    margin-right: 0;
  `}
`

const Flip = styled.div`
  ${media.md`
    transform: scaleX(-1);
  `}
`

const Hero = ({ bgColor }) => {

  const data = useStaticQuery(graphql`
    query {
      bribery: file(relativePath: { eq: "bribery.png" }) {
        childImageSharp {
          fluid(maxWidth: 1830) {
            ...GatsbyImageSharpFluid
          },
        }
      },
      timeChallenge: file(relativePath: { eq: "time-challenge.png" }) {
        childImageSharp {
          fluid(maxWidth: 1830) {
            ...GatsbyImageSharpFluid
          },
        }
      }
    }
  `)

  return (
    <SectionLayout bgColor={bgColor}>
      <ContentContainer>
        <Heading>
          There are probably only 2 foolproof parenting techniques you can use when you need your kids to do something.
        </Heading>
        <Main>
          <Subsection>
            <StepHeading>
              <Step>
                1
              </Step>
              <StepLabel>
                Bribery
              </StepLabel>
            </StepHeading>
            <StepDescription>
              Or shall we say <em>incentivizing</em>.
            </StepDescription>
            <StepImage1>
              <Flip>
                <Img
                  fluid={data.bribery.childImageSharp.fluid}
                  title="Bribery"
                  alt="Goody Points Bribery"
                />
              </Flip>
            </StepImage1>
          </Subsection>

          <Subsection>
            <StepHeading>
              <Step>
                2
              </Step>
              <StepLabel>
                Time Challenge  
              </StepLabel>
            </StepHeading>
            <StepDescription>
              "I'll time you!" always seems to work.
              </StepDescription>
            <StepImage2>
              <Img
                fluid={data.timeChallenge.childImageSharp.fluid}
                title="Time Challenge"
                alt="Goody Points Time Challenge"
              />
            </StepImage2>
          </Subsection>
        </Main>
      </ContentContainer>
    </SectionLayout>
  )
}

export default Hero
