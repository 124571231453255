import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { color, media } from "../../theme"

const Grid = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 100%;
  position: relative;

  ${media.sm`
      grid-template-columns: 50% 50%;
      padding-bottom: 0;
  `}
`

const Content = styled.div`
  text-align: center;

  ${media.sm`
      text-align: left;
  `}

  ${media.md`
      text-align: left;
      padding: 3rem 0 12%;
  `}

  ${media.lg`
      text-align: left;
  `}
`;

const Screen = styled.div`
  /* -10% margin enlarges image within grid a bit and bleed shadow to edge */
  margin-left: -10%;
  margin-right: -10%;
  margin-bottom: -12%;

  ${media.sm`
      margin: 0;
  `}

  ${media.lg`
      margin-bottom: -4%;
  `}
`;

const Heading = styled.h2`
  font-weight: 700;
  font-size: 1.3rem;
  color: ${color.DARK};
  margin-bottom: 0.5rem;

  ${media.lg`
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
  `}
`
const Blurb = styled.p`
  color: ${color.DARK};
  font-size: 1rem;
  line-height: 1.5;
  max-width: 90%;
  margin: 0 auto 2rem;

  ${media.xs`
    max-width: 80%;
  `}

  ${media.sm`
    margin: 0 auto 4rem;
    padding-right: 20%;
    max-width: 100%;
    margin: 0 auto 1rem;
  `}

  ${media.md`
    margin: 0 auto 2rem;
  `}
`

const Illustration = styled.div`
  padding: 0 2rem;
  ${media.sm`
    padding: 0 20% 0 0;
    max-width: 100%;
  `}
`;

const Screenshot = ({ screen, heading, blurb, illustration }) => (
  <Grid>
    <Screen>
      <Img
        fluid={screen}
        title="Goody Points Timer Menu Screen"
        alt="Goody Points Timer Menu Screen"
      />
    </Screen>
    <Content>
      <Heading>
        {heading}
      </Heading>
      <Blurb>
        {blurb}
      </Blurb>
      {illustration &&
        <Illustration>
          <Img
            fluid={illustration}
            title="Goody Points Pick Your Time"
            alt="Goody Points Pick Your Time"
          />
        </Illustration>
      }
    </Content>
  </Grid>
)

export default Screenshot
