import React from "react"
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const IosStoreButton = styled(OutboundLink)`
  margin-right: 1rem;
`

const PlayStoreButton = styled(OutboundLink)`
`

const Buttons = ({ ...props  }) => {
  const data = useStaticQuery(graphql`
    query {
      iosStore: file(relativePath: { eq: "ios-store-button.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          },
        }
      },
      playStore: file(relativePath: { eq: "play-store-button.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          },
        }
      },
    },
  `)

  // const alert = () => {
  //   window.alert('Coming in Jan 2021')
  // }

  return (
    <React.Fragment>
      <IosStoreButton href="https://apps.apple.com/us/app/goody-points-kid-timer/id1538659837">
        <Img
          fixed={data.iosStore.childImageSharp.fixed}
          title="Get Goody Points App for iOS"
          alt="Get Goody Points App for iOS"
        />
      </IosStoreButton>
      <PlayStoreButton href="https://play.google.com/store/apps/details?id=com.goodypoints.goodypointstimer">
        <Img
          fixed={data.playStore.childImageSharp.fixed}
          title="Get Goody Points App for Android"
          alt="Get Goody Points App for Android"
        />
      </PlayStoreButton>
    </React.Fragment>
  )
}

export default Buttons
