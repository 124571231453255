import React from "react"
import styled from "styled-components"

import { color } from "../../theme"

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.TEAL};
  padding: 0 16px;
  height: 28px;
  border-radius: 28px;
  background-color: ${color.CREME};
  text-decoration: none;
  font-weight: bold;
  font-size: 0.75rem;
  transition: all 0.5s ease;
  &:hover {
    background-color: #fff;
  }
`

const Button = ({href, children, ...props }) => {

  return (
    <Wrapper href={href} {...props}>{children}</Wrapper>
  )
}

export default Button
