import React from "react"
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"

import { color, media } from "../../theme"
import SectionLayout from "../SectionLayout"
import ContentContainer from "../ContentContainer"
import ScreenshotLayout from "../ScreenshotLayout"

const Heading = styled.h2`
  font-weight: 700;
  font-size: 1.8rem;
  color: ${color.DARK};
  margin-bottom: 1rem;

  ${media.sm`
    text-align: center;
    font-size: 2.2rem;
  `}

  ${media.lg`
    margin-bottom: 1.5rem;
  `}
`

const Description = styled.p`
  color: ${color.DARK};
  line-height: 1.6;
  margin-bottom: 3rem;
  font-size: 1.1rem;

  ${media.sm`
    max-width: 79%;
    margin: 0 auto 5rem;
  `}
`

const Section = ({ bgColor }) => {

  const data = useStaticQuery(graphql`
    query {
      screenshotImg: file(relativePath: { eq: "screen-timermenu.png" }) {
        childImageSharp {
          fluid(maxWidth: 1368) {
            ...GatsbyImageSharpFluid
          },
        }
      },
      illustration: file(relativePath: { eq: "illustration-timermenu.png" }) {
        childImageSharp {
          fluid(maxWidth: 1155) {
            ...GatsbyImageSharpFluid
          },
        }
      },
    },
  `)

  return (
    <SectionLayout>
      <ContentContainer>
        <Heading data-sal="bodyBg" data-color={bgColor}>
          Timers
        </Heading>
        <Description>
          Most kids respond to timers, especially if you make it a fun challenge.
          If not, you got a weird one.
        </Description>
        <ScreenshotLayout
          screen={data.screenshotImg.childImageSharp.fluid}
          heading="Pick your time"
          blurb="Quickly choose a duration that suits your needs in the moment. You will be able to create the perfect set of custom timers for your routine."
          illustration={data.illustration.childImageSharp.fluid}
        />
      </ContentContainer>
    </SectionLayout>
  )
}

export default Section
