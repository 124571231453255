import React from "react"
import styled from "styled-components"

import { color, media } from "../../theme"
import SectionLayout from "../SectionLayout"
import ContentContainer from "../ContentContainer"

import addPointsGIF from "../../images/add-points.gif"
import subtractPointsGIF from "../../images/subtract-points.gif"
import partyTimeGIF from "../../images/party-time.gif"

const Heading = styled.h2`
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.8rem;
  color: ${color.DARK};

  ${media.sm`
      text-align: center;
      margin: 0 auto;
      max-width: 79%;
      font-size: 2.2rem;
      margin-bottom 1.5rem;
  `}
`
const Description = styled.p`
  color: ${color.DARK};
  margin-bottom: 2rem;
  line-height: 1.6;
  font-size: 1.1rem;

  ${media.sm`
      margin: 0 auto;
      max-width: 79%;
  `}
`

const Subsection = styled.div`
  margin: 2.5rem 0;

  ${media.sm`
      margin: 3rem auto;
      display: flex;
      align-items: center;
      max-width: 79%;
  `}
`
const Content = styled.div`
  ${media.sm`
      width: 50%;
      order: 2;
      margin-left: 1.5rem;
  `}
`

const Subheading = styled.h3`
  text-align: center;
  color: ${color.DARK};
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  ${media.sm`
      text-align: left;
      margin-bottom: 0.5rem;
      font-size: 1.4rem;
  `}
`

const Blurb = styled.p`
  text-align: center;
  color: ${color.DARK};
  line-height: 1.5;
  margin: 0 auto 1.5rem;
  max-width: 375px;
  ${media.sm`
      text-align: left;
      max-width: auto;
      margin: 0;
  `}
`

const Card = styled.div`
  background-color: ${color.CREME};
  max-width: 343px;
  border-radius: 12px;
  display: flex;
  padding: 12px;
  margin: 0 1rem;

  ${media.sm`
      margin: 0;
  `}
`;

const GIF = styled.img`
  width: 100%;
  height: 100%;
  max-width: 343px;
  max-height: 250px;
`

const Section = ({ bgColor }) => {
  return (
    <SectionLayout data-sal="bodyBg" data-color={bgColor}>
      <ContentContainer>
        <Heading>
          Simple Point System
        </Heading>
        <Description>
          Reward systems can be one of the best ways to change a child's behavior.
          Goody Points app provides a simple way to add or subtract points and keep score.
          How you add or subtract points is up to you. Set a goal to motivate your children and
          reward them as you see fit!
        </Description>

        <Subsection>
          <Content>
            <Subheading>
              Add Points
            </Subheading>
            <Blurb>
              Add points when your kid does something good.
            </Blurb>
          </Content>
          <Card>
            <GIF src={addPointsGIF} alt="Adding Points" />
          </Card>
        </Subsection>

        <Subsection>
          <Content>
            <Subheading>
              Subtract Points
            </Subheading>
            <Blurb>
              Penalize when necessary or just to keep them honest.
            </Blurb>
          </Content>
          <Card>
            <GIF src={subtractPointsGIF} alt="Subtract Points" />
          </Card>
        </Subsection>
        
        <Subsection>
          <Content>
            <Subheading>
              Party Time
            </Subheading>
            <Blurb>
              Set a points goal at anytime and get ready to celebrate their good behavior when they reach it!
            </Blurb>
          </Content>
          <Card>
            <GIF src={partyTimeGIF} alt="Reaching Goal" />
          </Card>
        </Subsection>

      </ContentContainer>
    </SectionLayout>
  )
}

export default Section
