import React from "react"

import SEO from "../components/seo"

import Layout from "../components/Layout"
import HeroSection from "../components/HeroSection"
import WhySection from "../components/WhySection"
import MyKidsSection from "../components/MyKidsSection"
import TimerMenuSection from "../components/TimerMenuSection"
import TimerStartSection from "../components/TimerStartSection"
import TimerOnSection from "../components/TimerOnSection"
import OvertimeSection from "../components/OvertimeSection"
import TimerAwardSection from "../components/TimerAwardSection"
import SyncSection from "../components/SyncSection"
import Footer from "../components/Footer"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Kid Timer and Reward System" />
      <HeroSection bgColor="TEAL" />
      <WhySection bgColor="CREME" />
      <MyKidsSection bgColor="LIGHT_YELLOW" />
      <TimerMenuSection bgColor="LIGHT_GRAY" />
      <TimerStartSection bgColor="LIGHT_PURPLE" />
      <TimerOnSection bgColor="LIGHT_BLUE" />
      <OvertimeSection bgColor="RED" />
      <TimerAwardSection bgColor="LIGHT_PURPLE" />
      <SyncSection bgColor="LIGHT_TEAL" />
      <Footer bgColor="BLACK" />
    </Layout>
  )
}

export default IndexPage
